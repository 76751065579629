import 'leaflet/dist/leaflet.css';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Unstable_Grid2 as Grid,
    Button,
    ButtonGroup,
    Typography,
} from '@mui/material';

import {
    EditOutlined as EditIcon,
    FmdGood as FilledLocationIcon,
    Delete as DeleteIcon,
    Add as AddIcon
} from '@mui/icons-material';

import {
    SliderElement,
    TextFieldElement,
    useWatch
} from 'react-hook-form-mui';

import {
    useMapEvents,
    MapContainer,
    TileLayer
} from 'react-leaflet';

import { Circle } from 'react-leaflet/Circle';
import { styled, useTheme } from '@mui/material/styles';
import { MarkerLayer, Marker } from 'react-leaflet-marker';
import { useIntl, FormattedMessage } from 'react-intl';
import { DataGrid } from '@mui/x-data-grid';
import { useCustomForm } from '../../base/Form';
import { useApi, api } from '../../base/Request';
import { DialogTypes, useDialog } from '../../base/Dialog';

import AccessDuration from './AccessDuration';
import DefaultDialog from '../../../modules/DefaultDialog';

const DefaultValues = {
    Location: [52.06229077, 4.49014664],
    Zoom: 12,
    Radius: 20,
};

const LocationMarker = (props) => {
    const { data, form } = props;
    const { setValues } = form;

    const [markerPosition, setMarkerPosition] = useState(
        data?.latitude ? [data.latitude, data.longitude] : DefaultValues.Location
    );

    const updateMarkerPosition = useCallback((latlng, map) => {
        const { lat, lng } = latlng;

        setValues({
            latitude: lat.toFixed(8),
            longitude: lng.toFixed(8)
        });

        setMarkerPosition(latlng);

        map.flyTo(latlng, map.getZoom(), { animate: false });
    }, [setValues]);

    const updateMarkerPositionFromData = useCallback((latlng, map) => {
        const { lat, lng } = latlng;

        if (lat && lng) {
            setValues({
                latitude: lat,
                longitude: lng
            });

            setMarkerPosition(latlng);

            map.flyTo(latlng, map.getZoom(), { animate: false });
        } else if (markerPosition === DefaultValues.Location) {
            map.locate();
        }
    }, [setValues, markerPosition]);

    const map = useMapEvents({
        click: (event) => updateMarkerPosition(event.latlng, map),
        locationfound: (event) => updateMarkerPosition(event.latlng, map)
    });

    useEffect(() => {
        updateMarkerPositionFromData({
            lat: data?.latitude,
            lng: data?.longitude
        }, map);
    }, [data, map]);

    const CustomMarker = styled(FilledLocationIcon)(
        ({ theme }) => ({
            color: theme.palette.primary.main
        })
    );

    return (
        <Marker
            placement='center'
            position={markerPosition}
            size={[25, 25]}
        >
            <CustomMarker />
        </Marker>
    );
};

LocationMarker.propTypes = {
    data: PropTypes.object,
    form: PropTypes.object.isRequired
};

LocationMarker.defaultProps = {
    data: null
};

const GeoCoordinatesModal = (props) => {
    const {
        show,
        data,
        saveHandler,
        closeHandler
    } = props;

    const { formatMessage } = useIntl();
    const theme = useTheme();

    const retrieveData = async () => ({
        latitude: data?.latitude || DefaultValues.Location[0],
        longitude: data?.longitude || DefaultValues.Location[1],
        radius: data?.radius || DefaultValues.Radius,
    });

    const customForm = useCustomForm({ defaultValues: retrieveData });

    const {
        control,
        setValues,
        handleSubmit,
        reset
    } = customForm;

    useEffect(() => {
        setValues({
            latitude: data?.latitude || DefaultValues.Location[0],
            longitude: data?.longitude || DefaultValues.Location[1],
            radius: data?.maxGeoMetres || DefaultValues.Radius
        });
    }, [data]);

    const saveForm = (formData) => {
        saveHandler({
            id: data?.id || null,
            ...formData
        });

        reset();
        closeHandler();
    };

    const [latitude, longitude, radius] = useWatch({
        control,
        name: ['latitude', 'longitude', 'radius']
    });

    const sliderMarks = [
        {
            value: 0,
            label: 0,
        },
        {
            value: radius,
            label: radius,
        },
        {
            value: 500,
            label: 500,
        },
    ];

    return (
        <DefaultDialog
            closeHandler={() => {
                reset();
                closeHandler();
            }}
            saveButton={(
                <Button
                    onClick={handleSubmit(saveForm)}
                    size='large'
                    type='submit'
                    variant='contained'
                >
                    {data?.updated ? (
                        <FormattedMessage
                            defaultMessage='Update'
                            id='button.update'
                        />
                    ) : (
                        <FormattedMessage
                            defaultMessage='Add'
                            id='button.add'
                        />
                    )}
                </Button>
            )}
            show={show}
            size='modalMd'
            title={formatMessage({
                defaultMessage: 'Geo coordinates',
                id: 'campaigns.form.geo.geoCoordinates'
            })}
        >
            <Grid container spacing={2}>
                <Grid xs={6}>
                    <TextFieldElement
                        control={control}
                        InputProps={{ readOnly: true }}
                        label={formatMessage({
                            defaultMessage: 'Latitude',
                            id: 'campaigns.form.geo.latitude'
                        })}
                        name='latitude'
                        required
                        type='number'
                    />
                </Grid>
                <Grid sx={{ mb: 2 }} xs={6}>
                    <TextFieldElement
                        control={control}
                        InputProps={{ readOnly: true }}
                        label={formatMessage({
                            defaultMessage: 'Longitude',
                            id: 'campaigns.form.geo.longitude'
                        })}
                        name='longitude'
                        required
                        type='number'
                    />
                </Grid>
                <Grid xs={6}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Radius'
                            id='campaigns.form.geo.radius'
                        />
                    </Typography>
                </Grid>
                <Grid display='flex' justifyContent='flex-end' xs={6}>
                    <Typography variant='large400'>
                        {radius}
                        {' '}
                        {formatMessage({
                            defaultMessage: 'meter(s)',
                            id: 'campaigns.form.geo.unit.meters'
                        })}
                    </Typography>
                </Grid>
                <Grid sx={{ mx: 1.5 }} xs={12}>
                    <SliderElement
                        control={control}
                        label=''
                        marks={sliderMarks}
                        max={500}
                        min={0}
                        name='radius'
                        size='small'
                        valueLabelDisplay='off'
                    />
                </Grid>
                <Grid sx={{ my: 1 }} xs={12}>
                    <MapContainer
                        center={DefaultValues.Location}
                        style={{
                            width: '100%',
                            height: '400px'
                        }}
                        zoom={DefaultValues.Zoom}
                    >
                        <TileLayer
                            attribution={(
                                '&copy; <a href="https://www.openstreetmap.org/copyright">'
                                + 'OpenStreetMap</a> contributors'
                            )}
                            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        />
                        <MarkerLayer>
                            <LocationMarker
                                data={data}
                                form={customForm}
                            />
                            <Circle
                                center={[latitude, longitude]}
                                pathOptions={{
                                    fillColor: theme.palette.primary.main,
                                    fillOpacity: 0.1,
                                    color: theme.palette.primary.main,
                                    opacity: 0.8
                                }}
                                radius={radius}
                            />
                        </MarkerLayer>
                    </MapContainer>
                </Grid>
            </Grid>
        </DefaultDialog>
    );
};

GeoCoordinatesModal.propTypes = {
    closeHandler: PropTypes.func.isRequired,
    data: PropTypes.object,
    saveHandler: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};

GeoCoordinatesModal.defaultProps = {
    data: null
};

const GeoLocationActions = (props) => {
    const {
        editCoordinate,
        deleteCoordinate,
        id,
    } = props;

    return (
        <ButtonGroup>
            <Button
                onClick={() => {
                    editCoordinate(id);
                }}
                size='small'
                startIcon={<EditIcon />}
                variant='onlyIcon'
            />
            <Button
                onClick={() => {
                    deleteCoordinate(id);
                }}
                size='small'
                startIcon={<DeleteIcon />}
                variant='onlyIcon'
            />
        </ButtonGroup>
    );
};

GeoLocationActions.propTypes = {
    deleteCoordinate: PropTypes.func.isRequired,
    editCoordinate: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
};

export default function GeoLocation(props) {
    const {
        backHandler,
        control,
        data,
        campaignId,
        formHandler,
        show,
        isUpdate
    } = props;

    const { formatMessage } = useIntl();
    const [showGeoCoordinatesModal, setShowGeoCoordinatesModal] = useState(false);
    const [geoCoordinates, setGeoCoordinates] = useState(data?.geoCoordinates || []);
    const [currentCoordinate, setCurrentCoordinate] = useState(null);

    const { request } = useApi();
    const { createDialog } = useDialog();

    const getCoordinatesFromResponse = (response) => {
        const coordinates = response?.campaignCoordinates;

        if (response.success && Array.isArray(coordinates)) {
            return coordinates;
        }

        console.error('error retrieving ip addresses data');

        return [];
    };

    const retrieveData = async () => {
        if (campaignId) {
            const response = await request(api.getCoordinates).send({ campaignId });

            const coordinates = getCoordinatesFromResponse(response).map((coordinate) => ({
                id: coordinate.id,
                latitude: Number(coordinate.latitude).toFixed(8),
                longitude: Number(coordinate.longitude).toFixed(8),
                maxGeoMetres: coordinate.maxGeoMetres,
            }));

            setGeoCoordinates(coordinates);
        }
    };

    const saveForm = () => {
        formHandler({ geoCoordinates });
    };

    const updateGeoCoordinate = async (formData) => {
        if (campaignId) {
            await request(api.updateCoordinate).send({
                campaignId,
                id: formData.id,
                latitude: formData.latitude,
                longitude: formData.longitude,
                maxGeoMetres: formData.radius,
            });

            await retrieveData();
        } else {
            const toUpdate = {
                id: formData.id,
                latitude: Number(formData.latitude).toFixed(8),
                longitude: Number(formData.longitude).toFixed(8),
                maxGeoMetres: formData.radius,
            };

            setGeoCoordinates((coordinates) => coordinates.map(
                (coordinate) => (
                    coordinate.id === currentCoordinate.id ? { ...coordinate, ...toUpdate } : coordinate
                )
            ));
        }
    };

    const createGeoCoordinate = async (formData) => {
        if (campaignId) {
            await request(api.createCoordinate).send({
                campaignId,
                latitude: formData.latitude,
                longitude: formData.longitude,
                maxGeoMetres: formData.radius,
            });
        } else {
            const index = geoCoordinates.reduce((max, coo) => (coo.id > max ? coo.id : max), 0);

            setGeoCoordinates([...geoCoordinates, {
                id: index + 1,
                latitude: Number(formData.latitude).toFixed(8),
                longitude: Number(formData.longitude).toFixed(8),
                maxGeoMetres: formData.radius,
            }]);
        }

        await retrieveData();
    };

    const saveGeoCoordinate = async (formData) => {
        const action = formData.id ? updateGeoCoordinate : createGeoCoordinate;
        await action(formData);

        setShowGeoCoordinatesModal(false);
    };

    const editCoordinate = (id) => {
        const coordinate = geoCoordinates.find((item) => item.id === id);

        setCurrentCoordinate({
            ...coordinate,
            updated: true
        });

        setShowGeoCoordinatesModal(true);
    };

    const deleteCoordinate = async (id) => {
        const dialog = createDialog({
            type: DialogTypes.Confirm,
        });

        dialog.onConfirm(async () => {
            if (campaignId) {
                await request(api.deleteCoordinate).send({ campaignId, id, });
                await retrieveData();
            } else {
                setGeoCoordinates(
                    geoCoordinates.filter((coordinate) => coordinate.id !== id)
                );
            }
        });

        dialog.show();
    };

    const geoCoordinatesColumns = [
        {
            field: 'latitude',
            headerName: formatMessage({ defaultMessage: 'Latitude', id: 'campaigns.form.geo.latitude' }),
            flex: 1.5,
        },
        {
            field: 'longitude',
            headerName: formatMessage({ defaultMessage: 'Longitude', id: 'campaigns.form.geo.longitude' }),
            flex: 1,
        },
        {
            field: 'radius',
            headerName: formatMessage({ defaultMessage: 'Radius', id: 'campaigns.form.geo.radius' }),
            flex: 1,
            valueGetter: (value) => `${value.row.maxGeoMetres} meter(s)`
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.6,
            sortable: false,
            align: 'right',
            renderCell: (value) => (
                <GeoLocationActions
                    deleteCoordinate={deleteCoordinate}
                    editCoordinate={editCoordinate}
                    id={value.row.id}
                />
            )
        }
    ];

    useEffect(() => {
        retrieveData();
    }, []);

    return (
        <Grid
            className='alternate-rows'
            container
            direction='column'
            spacing={2}
            sx={{ px: 2, my: 2, display: show ? 'flex' : 'none' }}
            xs={12}
        >
            <Grid container sx={{ p: 3 }}>
                <Grid xs={3}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Geo coordinates'
                            id='campaigns.form.geo.geoCoordinates'
                        />
                    </Typography>
                </Grid>
                <Grid container xs={9}>
                    <Grid sx={{ mb: 1 }} xs={12}>
                        <Button
                            onClick={() => {
                                setCurrentCoordinate(null);
                                setShowGeoCoordinatesModal(true);
                            }}
                            startIcon={<AddIcon />}
                            variant='contained'
                        >
                            <FormattedMessage
                                defaultMessage='Add geolocation point'
                                id='campaigns.form.geo.addPoint'
                            />
                        </Button>
                    </Grid>
                    <Grid xs={12}>
                        <DataGrid
                            autoHeight
                            columns={geoCoordinatesColumns}
                            disableColumnMenu
                            hideFooter
                            loading={!geoCoordinates}
                            rowHeight={52}
                            rows={geoCoordinates}
                            sx={{
                                border: 'none',
                                '& .MuiDataGrid-columnHeaders': {
                                    borderRadius: 1
                                },
                                '& .MuiDataGrid-columnHeader': {
                                    backgroundColor: 'common.white'
                                },
                                '& .MuiDataGrid-row': {
                                    borderRadius: 1,
                                    '&:nth-of-type(even)': {
                                        backgroundColor: 'common.white'
                                    }
                                },
                                '& .MuiDataGrid-cell': {
                                    borderBottom: 'none'
                                },
                                '& .MuiDataGrid-columnHeader:focus': {
                                    outline: 'none',
                                },
                                '& .MuiDataGrid-cell:focus': {
                                    outline: 'none',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <AccessDuration control={control} />
            <Grid container sx={{ my: 3 }}>
                <Grid
                    display='flex'
                    justifyContent='flex-end'
                    xs={12}
                >
                    <Button onClick={backHandler} size='large'>
                        <FormattedMessage defaultMessage='Back' id='button.back' />
                    </Button>
                    <Button
                        onClick={saveForm}
                        size='large'
                        type='submit'
                        variant='contained'
                    >
                        {isUpdate ? (
                            <FormattedMessage defaultMessage='Update campaign' id='campaigns.form.update' />
                        ) : (
                            <FormattedMessage defaultMessage='Create campaign' id='campaigns.form.create' />
                        )}
                    </Button>
                </Grid>
            </Grid>
            <GeoCoordinatesModal
                closeHandler={() => {
                    setCurrentCoordinate(null);
                    setShowGeoCoordinatesModal(false);
                }}
                data={currentCoordinate}
                saveHandler={saveGeoCoordinate}
                show={showGeoCoordinatesModal}
            />
        </Grid>
    );
}

GeoLocation.propTypes = {
    backHandler: PropTypes.func.isRequired,
    campaignId: PropTypes.number.isRequired,
    control: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    formHandler: PropTypes.func.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired
};
