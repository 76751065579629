import stringToCase from 'to-case';
import AddIcon from '@mui/icons-material/Add';
import { useIntl } from 'react-intl';

/**
 * Get the page title from the path
 * @param fullPath
 * @returns {string}
 */
export const getPageName = (fullPath) => {
    const pathParts = fullPath.split('/').slice(0, 3);
    const path = pathParts.join('/');
    switch (path) {
        case '/':
            return 'Dashboard';
        case '/login':
            return 'Login';
        case '/forgot-password':
            return 'Forgot Password';
        case '/account':
            return 'Account Settings';
        case '/newsstand':
            return 'Newsstand';
        case '/newsstand/settings':
            return 'General settings';
        case '/newsstand/layout':
            return 'Visual layout';
        case '/newsstand/publications':
            return 'Publications';
        case '/newsstand/supplements':
            return 'Supplements';
        case '/campaigns':
            return 'Campaigns';
        case '/campaigns/add':
            return 'Add Campaign';
        case '/campaigns/view':
            return 'View Campaign';
        case '/campaigns/edit':
            return 'Edit Campaign';
        case '/adverts':
            return 'Adverts';
        case '/highlights':
            return 'Highlights';
        case '/logout':
            return 'Logout';
        case '/help':
            return 'Help';
        default:
            return 'Unknown';
    }
};

export const getParentPath = (path) => `/${path.split('/')[1]}`;

/**
 * Get the main page name from the path. When a child page is active return the parent.
 * @param path
 * @returns {string}
 */
export const getMainPageName = (path) => {
    const mainPagePath = getParentPath(path);
    return getPageName(mainPagePath);
};

export const getTranslatedPageName = (formatMessage, path) => {
    const pageName = getPageName(path);
    return formatMessage({
        defaultMessage: pageName,
        id: `pages.${stringToCase.camel(pageName)}`
    });
};

/**
 * Get the header button params from the path (optional).
 * @param path
 * @returns {ReactNode}
 */
export const getHeaderButton = (path) => {
    const { formatMessage } = useIntl();
    switch (path) {
        case '/campaigns':
            return {
                icon: AddIcon,
                text: formatMessage({ defaultMessage: 'Add campaign', id: 'campaigns.addNew' }),
                route: '/campaigns/add'
            };
        case '/highlights':
            return {
                icon: AddIcon,
                text: formatMessage({ defaultMessage: 'Add new Highlight', id: 'highlights.addNew' }),
                route: '/highlights/add'
            };
        case '/adverts':
            return {
                icon: AddIcon,
                text: formatMessage({ defaultMessage: 'Add new Advert', id: 'adverts.addNew' }),
                route: '/adverts/add'
            };
        default:
            return null;
    }
};
