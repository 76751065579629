import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Typography,
    Unstable_Grid2 as Grid
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    useForm,
    CheckboxElement,
    FormContainer,
    TextFieldElement,
    Controller,
} from 'react-hook-form-mui';
import RequestButton from '../../../components/RequestButton';
import TextEditor from '../../../modules/TextEditor/TextEditor';

const Settings = (props) => {
    const { getData, submitData } = props;
    const { formatMessage } = useIntl();
    const {
        control,
        formContext,
        handleSubmit,
        reset
    } = useForm({ defaultValues: getData });
    const privacyPolicyInputRef = useRef(null);

    const submitDataSuccess = async () => {
        const newData = await getData();
        reset(newData);
    };

    return (
        <FormContainer
            formContext={formContext}
            onSubmit={handleSubmit(submitData)}
        >
            <Grid
                className='alternate-rows'
                container
                direction='column'
                spacing={2}
                sx={{ px: 2 }}
                xs={12}
            >
                <Grid
                    container
                    sx={{ my: 0.5 }}
                >
                    <Grid xs={4}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='General settings'
                                id='newsstand.settings.generalSettings'
                            />
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction='column'
                        spacing={2}
                        xs={8}
                    >
                        <Grid>
                            <TextFieldElement
                                control={control}
                                InputProps={{ disableUnderline: true }}
                                label={formatMessage({
                                    defaultMessage: 'Domain name',
                                    id: 'newsstand.settings.form.domainName',
                                })}
                                name='domain'
                                variant='standard'
                            />
                        </Grid>
                        <Grid>
                            <CheckboxElement
                                control={control}
                                label={formatMessage({
                                    defaultMessage: 'Disable speech',
                                    id: 'newsstand.settings.form.disableSpeech',
                                })}
                                name='disableSpeech'
                            />
                        </Grid>
                        <Grid display='none'>
                            <CheckboxElement
                                control={control}
                                label={formatMessage({
                                    defaultMessage: 'Enable adverts on main page',
                                    id: 'newsstand.settings.form.enableAdverts',
                                })}
                                name='enableAdverts'
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{ mt: 1, mb: 0, pb: 0 }}
                >
                    <Grid xs={4}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Registration'
                                id='newsstand.settings.registration'
                            />
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction='column'
                        spacing={2}
                        xs={8}
                    >
                        <Grid>
                            <CheckboxElement
                                control={control}
                                label={formatMessage({
                                    defaultMessage: 'Registration form',
                                    id: 'newsstand.settings.form.enableEmailRegistration'
                                })}
                                name='enableEmailRegistration'
                            />
                        </Grid>
                        <Grid>
                            <CheckboxElement
                                control={control}
                                label={formatMessage({
                                    defaultMessage: 'Allow immediate access',
                                    id: 'newsstand.settings.form.allowImmediateAccess'
                                })}
                                name='immediateAccess'
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{ my: 0, pb: 0 }}
                >
                    <Grid xs={4}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Email access'
                                id='newsstand.settings.emailAccess'
                            />
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction='column'
                        spacing={2}
                        xs={8}
                    >
                        <Grid>
                            <CheckboxElement
                                control={control}
                                label={formatMessage({
                                    defaultMessage: 'Email validation',
                                    id: 'newsstand.settings.form.emailRequired'
                                })}
                                name='emailRequired'
                            />
                        </Grid>
                        <Grid>
                            <CheckboxElement
                                control={control}
                                label={formatMessage({
                                    defaultMessage: 'Allow immediate access',
                                    id: 'newsstand.settings.form.allowImmediateAccess'
                                })}
                                name='immediateEmailAccess'
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{ mt: 0, mb: 0.5 }}
                >
                    <Grid xs={4}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Privacy policy'
                                id='newsstand.settings.privacyPolicy'
                            />
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        xs={8}
                    >
                        <Grid xs={12}>
                            <Controller
                                control={control}
                                name='privacyPolicy'
                                render={({ field }) => {
                                    if (field.value === undefined) return null;
                                    return (
                                        <TextEditor
                                            {...field}
                                            ref={privacyPolicyInputRef}
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid
                        display='flex'
                        justifyContent='flex-end'
                        xs={12}
                    >
                        <RequestButton
                            injectFormData={handleSubmit}
                            onSuccess={submitDataSuccess}
                            sendData={submitData}
                            size='large'
                            type='submit'
                            variant='contained'
                        >
                            <FormattedMessage
                                defaultMessage='Update'
                                id='common.update'
                            />
                        </RequestButton>
                    </Grid>
                </Grid>
            </Grid>
        </FormContainer>
    );
};

Settings.propTypes = {
    getData: PropTypes.func.isRequired,
    submitData: PropTypes.func.isRequired,
};

export default Settings;
