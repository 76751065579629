// eslint-disable-next-line no-unused-vars
import { useIntl, IntlShape } from 'react-intl';

import { useTheme } from '@mui/material/styles';
import { useContext } from 'react';
import { actions, baseUrl } from '../../utils/api';
import { useAuth } from '../../contexts/Auth';
import { DialogTypes, useDialog } from './Dialog';
import { DialogContext } from '../../contexts/Dialog';

/**
 * @typedef RouteType
 * @property {string} path
 * @property {string} method
 * @property {boolean} [includeToken=true]
 */

/**
 * @typedef MethodTypes
 * @property {string} GET
 * @property {string} POST
 * @property {string} DELETE
 * @property {string} PUT
 * @property {string} PATCH
 */

/**
 * @typedef RequestMethodOptions
 * @property {?string} url
 * @property {?Object} data
 * @property {boolean} includeToken
 * @property {AbortSignal} signal
 */

/**
 * @typedef RequestOptions
 * @property {string} url
 * @property {Object} data
 * @property {keyof MethodTypes} method
 * @property {boolean} includeToken
 * @property {?AbortSignal} signal
 */

export const api = { ...actions };

/**
 * @enum {Record}
 * @type {Record<keyof MethodTypes, string>}
 */
export const MethodTypes = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
    PUT: 'PUT',
    PATCH: 'PATCH',
};

/**
 * @type {number}
 */
const MAX_TTL_LIFECYCLE_DURATION = 180000; // 3 min

/**
 * @param {RequestOptions} options
 * @param auth
 * @return {Promise<Response>}
 */
export const request = async (options, auth = useAuth()) => {
    const {
        url: _url,
        method,
        data,
        includeToken,
        signal,
        noCache,
    } = options;

    const token = auth.stored?.token;
    const conf = { method, signal };

    if (method !== MethodTypes.GET) {
        conf.headers = { 'Content-Type': 'application/json' };

        const body = includeToken
            ? { ...data, token }
            : data;

        conf.body = JSON.stringify(body);
    }

    let url = baseUrl + _url;

    if (includeToken) {
        url += (url.includes('?') ? '&token=' : '?token=') + token;
    }

    if (noCache) {
        url += (url.includes('?') ? '&_timestamp=' : '?_timestamp=') + Date.now();
    }

    return fetch(url, conf);
};

/**
 * @typedef {Object} RequestEventTypes
 * @property {number} Error
 * @property {number} Timeout
 */

/**
 * @readonly
 * @enum {number}
 * @type {RequestEventTypes}
 */
export const RequestEventTypes = {
    Error: 0,
    Timeout: 1,
};

/**
 * @typedef RequesterOptions
 * @property {(RequestMethodOptions) => Promise<Response>} callback
 */

/**
 * @param {(RequestMethodOptions) => Promise<Response>} _callback
 * @param {RouteType} route
 * @param options
 */
export const requester = (_callback, route, options) => {
    const { path, includeToken = true } = route;

    const { createDialog } = useDialog(options.intl, options.context);

    let methods;
    let showDialog = true;

    /**
     * @type {Record<string, (response: Response|Error) => void>}
     */
    const events = {
        [RequestEventTypes.Error]: () => {
        },
        [RequestEventTypes.Timeout]: () => {
        },
    };

    /**
     * @param {function(Error): void} callback
     */
    const onError = (callback) => {
        events[RequestEventTypes.Error] = callback;

        return methods;
    };

    /**
     * @param {boolean} option
     */
    const setShowDialog = (option) => {
        showDialog = option;

        return methods;
    };

    /**
     * @param {function(Response): void} callback
     */
    const onTimeout = (callback) => {
        events[RequestEventTypes.Timeout] = callback;

        return methods;
    };

    /**
     * @param {Object} data
     * @return {Promise<any|null>}
     */
    const send = async (data = {}) => {
        const controller = new AbortController();
        const ttl = setTimeout(() => controller.abort(), options.ttl);

        const response = await _callback({
            url: path,
            signal: controller.signal,
            includeToken,
            noCache: options.noCache,
            data,
        });

        clearTimeout(ttl);

        if (!response || response.status >= 400 || response.status === 204) {
            if (response?.status === 401) {
                options.auth.signout();

                return null;
            }

            if (showDialog) {
                const clone = await response.clone()?.json();

                const { show } = createDialog({
                    type: DialogTypes.Error,
                    body: clone?.serverStatus
                });

                show();
            }

            events[RequestEventTypes.Error]?.(response);

            return null;
        }

        return response.json();
    };

    methods = {
        send,
        onError,
        onTimeout,
        setShowDialog,
    };

    return methods;
};

/**
 * @param auth
 * @param intl
 */
export const useApi = (auth = useAuth(), intl = useIntl()) => {
    const theme = useTheme();
    const context = useContext(DialogContext);

    /**
     * @type {RequestMethodOptions}
     * @private
     */
    const _options = {
        url: null,
        data: null,
        includeToken: true,
        signal: null,
    };

    /**
     * @type {Record<MethodTypes, function>}
     */
    const methods = {
        [MethodTypes.GET]: (options = _options) => {
            let { url } = options;

            if (Object.keys(options?.data || {}).length) {
                url += (url.includes('?') ? '&' : '?') + new URLSearchParams(options.data).toString();
            }

            return request({
                url,
                data: {},
                method: MethodTypes.GET,
                includeToken: options.includeToken,
                signal: null
            }, auth);
        },

        [MethodTypes.POST]: (options = _options) => request({
            method: MethodTypes.POST,
            ...options
        }, auth),

        [MethodTypes.PATCH]: (options = _options) => request({
            method: MethodTypes.PATCH,
            ...options
        }, auth),

        [MethodTypes.PUT]: (options = _options) => request({
            method: MethodTypes.PUT,
            ...options
        }, auth),

        [MethodTypes.DELETE]: (options = _options) => request({
            method: MethodTypes.DELETE,
            ...options
        }, auth),
    };

    /**
     * @private
     */
    const _requestOptions = {
        auth,
        intl,
        context,
        noCache: false,
        ttl: MAX_TTL_LIFECYCLE_DURATION
    };

    /**
     * @param {RouteType} route
     * @param {typeof _requestOptions} [options={}]
     */
    const _request = (route, options) => {
        const callback = methods?.[route.method.toUpperCase()];

        return requester(callback, route, { theme, ..._requestOptions, ...options });
    };

    /**
     * @return {string}
     */
    const getDatabase = () => auth.stored?.database;

    return {
        request: _request,
        getDatabase
    };
};

/**
 * @param {string} url
 * @return {string}
 */
export const noCacheUrl = (url) => url + (url.includes('?') ? '&_timestamp=' : '?_timestamp=') + Date.now();
