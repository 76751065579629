import React from 'react';
import { styled } from '@mui/system';
import { Button, CircularProgress } from '@mui/material';
import {
    Error as ErrorIcon,
} from '@mui/icons-material';

import PropTypes from 'prop-types';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function FileButton(props) {
    const [status, setStatus] = React.useState(null);
    const {
        accept,
        children,
        disabled,
        handleUpload,
        maxFileSize
    } = props;
    // Create button props, filter out input props
    const buttonProps = { ...props };
    delete buttonProps.accept;
    delete buttonProps.children;
    delete buttonProps.handleUpload;
    delete buttonProps.maxFileSize;

    const onFileSelected = (files) => {
        if (!files.length) { return; }
        const selectedFile = files[0];
        // Get size and check
        if (selectedFile.size > maxFileSize * 1024 * 1024) {
            console.error('File size is too large');
            setStatus('error');
            return;
        }
        // Get filename
        const fileName = selectedFile?.name;
        // Get blob from file
        setStatus('loading');
        const reader = new FileReader();
        reader.onload = async (event) => {
            await handleUpload(fileName, event.target.result);
            setStatus('done');
        };
        // on error
        reader.onerror = () => {
            setStatus('error');
        };
        reader.readAsDataURL(selectedFile);
    };

    if (status === 'loading') {
        return (
            <Button
                {...buttonProps}
                disabled
                endIcon={<CircularProgress color='inherit' size={22} />}
            />
        );
    }

    // Extend sx if existing with error
    if (status === 'error') {
        buttonProps.sx = {
            ...buttonProps.sx,
            border: '1px solid red'
        };
    }
    // Change icon for ErrorIcon if status is error
    if (status === 'error') {
        buttonProps.startIcon = <ErrorIcon sx={{ color: 'primary.delete' }} />;
    }
    return (
        <Button
            {...buttonProps}
            component='label'
            role={undefined}
            tabIndex={-1}
        >
            {children}
            <VisuallyHiddenInput
                accept={accept}
                disabled={disabled}
                multiple={false}
                onChange={(event) => onFileSelected(event.target.files)}
                type='file'
            />
        </Button>
    );
}

FileButton.propTypes = {
    ...Button.propTypes,
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    handleUpload: PropTypes.func.isRequired,
    maxFileSize: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

FileButton.defaultProps = {
    ...Button.defaultProps,
    accept: '*',
    value: null,
    maxFileSize: 10,
};
